import React, { useState } from 'react';
import google from '../images/logos/google.png';
import yelp from '../images/logos/yelp.png';
import fb from '../images/logos/fb.png';
import angies from '../images/logos/angies.png';
import porch from '../images/logos/porch.png';
import houzz from '../images/logos/houzz.png';
import guildquality from '../images/logos/guildquality.png';
import buildzoom from '../images/logos/buildzoom.png';
import { ChevronRight, Star } from 'react-feather';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import ReviewsAllModalPopup from '../components/reviews-all-modal-popup';

const sources = [
	{
		alt: 'Yelp Reviews',
		src: '/assets/logos/yelp.png',
		style: { maxWidth: '72px', marginTop: '-4px' },
		rating: '4.5/5',
		readSourceName: 'Yelp',
		showReadMoreMobile: true
	},
	{
		alt: 'Google Reviews',
		src: '/assets/logos/google.png',
		style: { maxWidth: '75px' },
		rating: '4.8/5',
		readSourceName: 'Google'
	},
	{
		alt: 'Facebook Reviews',
		src: '/assets/logos/fb.png',
		style: { maxWidth: '85px' },
		rating: '5/5',
		readSourceName: 'Facebook'
	},
	{
		alt: "Angie's List Reviews",
		src: '/assets/logos/angies.png',
		style: { maxWidth: '85px' },
		rating: '4.9/5',
		readSourceName: "Angie's List"
	},
	{
		alt: 'Houzz Reviews',
		src: '/assets/logos/houzz.png',
		style: { maxWidth: '85px' },
		rating: '5/5',
		readSourceName: 'Houzz'
	},
	{
		alt: 'GuildQuality Reviews',
		src: '/assets/logos/guildquality.png',
		style: { maxWidth: '85px' },
		rating: '4.8/5',
		readSourceName: 'GuildQuality'
	}
];

const SocialReviewsFullwidth = ({
	serviceName,
	reviewsFilterID,
	modalLinkTo
}) => {
	const StarsContainer = styled.div`
		display: flex;
		align-items: center;
		svg {
			fill: ${props => props.fillColor || '#ffbc00'};
			stroke: none;
		}
	`;
	const Stars = ({ size, fillColor }) => (
		<StarsContainer fillColor={fillColor ? fillColor : null}>
			<Star size={size ? size : 25} />
			<Star size={size ? size : 25} />
			<Star size={size ? size : 25} />
			<Star size={size ? size : 25} />
			<Star size={size ? size : 25} />
		</StarsContainer>
	);

	const [reviewsModalOpen, setReviewsModalOpen] = useState(false);

	function toggleReviewsModal(e) {
		setReviewsModalOpen(e);
	}

	return (
		<div className="bg-full-screen-reviews relative hidden">
			<div className="container flex flex-wrap py-3">
				{/* <div className="w-full  text-center">
          <h2 className="mb-1">What Customers Are Saying</h2>
          <p className="text-gray-700">
            Our promptness, pricing and workmanship are among the many things
            customers noted make us stand out.
          </p>
        </div> */}
				<div className="w-full flex items-center">
					<div className="grid grid-cols-3 md:grid-cols-6 gap-3 md:gap-5 text-center w-full">
						{/* {reviewsFilterID} */}
						{sources.map(
							(
								{
									src,
									alt,
									style,
									rating,
									readSourceName,
									showReadMoreMobile
								},
								i
							) => (
								<Link
									className="flex flex-col items-center justify-between col-span-1 md:col-span-2 lg:col-span-1 py-2 px-3 bg-white shadow-md hover:shadow-lg hover:bg-white hover:cursor-pointer cursor-pointer border-radius"
									to={modalLinkTo}
									state={{
										modal: true
									}}
									key={i}
								>
									<img
										src={src}
										alt={alt}
										className="mb-0"
										style={style}
										width="auto"
										height="auto"
									/>
									<div>
										<span className="block text-gray-900 font-bold">
											{rating}
										</span>
										<span className="block text-xxs text-gray-600">
											Read
											{showReadMoreMobile && (
												<span className="sm:hidden">
													&nbsp;More
												</span>
											)}
											&nbsp;{readSourceName} Reviews
										</span>
									</div>
								</Link>
							)
						)}
					</div>
				</div>
				{/* <button
          type="button"
          onClick={() => setReviewsModalOpen(!reviewsModalOpen)}
          className="mt-2 button-style-primary button-show-more-reviews mx-auto"
        >
          <span className="flex items-center">
            Read {serviceName} Reviews <ChevronRight />
          </span>
        </button> */}
			</div>
		</div>
	);
};

export default SocialReviewsFullwidth;
